function getFilteringString() {
  const checkboxes = Array.from(
    document.getElementsByClassName("type-checkbox"),
  );
  const searchInput = document.getElementById("search-input");

  const selectedTypes = checkboxes.filter(ch => ch.checked).map(ch => ch.value);
  const searchPhrase = searchInput.value;

  const filters = [];

  if (selectedTypes.length) {
    filters.push("type=" + selectedTypes.join(","));
  }

  if (searchPhrase) {
    filters.push("phrase=" + searchPhrase);
  }

  return filters.join("&");
}

function applyFilters() {
  let filteringString = getFilteringString();
  if (filteringString) {
    filteringString = "?" + filteringString;
  }

  window.open(
    location.protocol +
      "//" +
      location.host +
      location.pathname +
      filteringString,
    "_self",
  );
}

export function initKnowledgeBase() {
  Array.from(
    document.getElementsByClassName("type-checkbox"),
  ).forEach(checkbox => checkbox.addEventListener("change", applyFilters));

  document.getElementById("search-input").addEventListener("keyup", event => {
    event.preventDefault();
    if (event.code === "Enter") {
      applyFilters();
    }
  });
}
